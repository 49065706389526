<template>
    <div class="w-full h-full flex flex-col">
        <div 
            v-if="customers"
            class="
                flex-none 
                flex 
                items-center
                flex-col
                
                md:h-32
                md:py-8
                md:px-16
                md:flex-row
            ">
            
            <div 
                class="
                    flex items-center justify-center
                    
                    h-12 
                    w-full
                    bg-clay
                    md:bg-transparent
                    
                    md:h-auto
                    md:w-auto
                ">
                
                <h1 class="text-white md:text-black">
                    {{ $t('customers.customers') }}
                </h1>
            </div>
            
            <div class="w-full flex items-center px-4 md:px-4 h-20">
                <div class="flex-grow flex items-center">
                    <div 
                        class="
                            mr-4 relative
                            w-full
                            md:w-64
                        ">
                        
                        <input 
                            class="w-full flex-none rounded-full px-8 py-3 outline-none "
                            :placeholder="$t('customers.searchCustomers')"
                            v-model="searchString"
                        />
                        
                        <i 
                            v-if="!searchString" 
                            class="fas fa-search text-lg text-gray-400 absolute right-0 top-0 mt-4 mr-4 pointer-events-none" 
                        />
                        <i 
                            v-else
                            class="fas fa-times text-lg text-gray-400 absolute right-0 top-0 mt-4 mr-5" 
                            @click="searchString = ''"
                        />
                    </div>
                    <h2 
                        v-if="searchString"
                        class="opacity-50 ml-8 hidden md:block">
                        
                        {{customersFiltered.length}} {{ $t('general.hitsOnSearch') }}
                    </h2>
                </div>
                <div class="flex-none flex">
                    <div 
                        class="fas fa-redo-alt block flex items-center justify-center bg-white text-gray-400 w-10 h-10 mr-4 rounded-full cursor-pointer refresh"
                        :class="{'loading': refreshing}"
                        @click="refresh()"
                    />
                    
                    <button 
                        @click="showCustomerNew = true"
                        class="bg-cyan text-white w-10 h-10 rounded-full">
                        
                        <i class="fas fa-plus text-lg" />
                    </button>
                </div>
            </div>
            
        </div>
        
        <!-- 
        <div 
            v-if="customers"
            class="flex-none py-8 px-16 flex items-center h-32">
            
            <h1 class="mr-8">Customers</h1>
            
            <div class="flex-grow flex items-center">
                <div class="w-64 relative">
                    <input 
                        class="w-full flex-none rounded-full px-8 py-3 outline-none "
                        placeholder="Search Customers"
                        v-model="searchString"
                    />
                    
                    <i 
                        v-if="!searchString" 
                        class="fas fa-search text-lg text-gray-400 absolute right-0 top-0 mt-4 mr-4 pointer-events-none" 
                    />
                    <i 
                        v-else
                        class="fas fa-times text-lg text-gray-400 absolute right-0 top-0 mt-4 mr-5" 
                        @click="searchString = ''"
                    />
                </div>
                <h2 
                    v-if="searchString"
                    class="opacity-50 ml-8">
                    
                    {{customersFiltered.length || 'No '}} hits on search
                </h2>
            </div>
            <div class="flex-none flex">
                <div 
                    class="fas fa-redo-alt block flex items-center justify-center bg-white text-gray-400 w-10 h-10 mr-4 rounded-full cursor-pointer refresh"
                    :class="{'loading': refreshing}"
                    @click="refresh()"
                />
                
                <button 
                    @click="showCustomerNew = true"
                    class="bg-cyan text-white w-10 h-10 mr-4 rounded-full">
                    
                    <i class="fas fa-plus text-lg" />
                </button>
            </div>
        </div>
        
    -->
        <div 
            class="
                w-full h-full flex-grow overflow-y-scroll 
                p-4
                pt-0
                md:px-16 
                md:pb-16
            ">
            
            <div 
                v-if="!customers"
                class="fixed inset-0 flex items-center justify-center">
                
                <div class="spinner"></div>
            </div>
            
            <transition-group 
                appear
                name="slide-up" 
                tag="div"
                class="
                    grid 
                    grid-cols-1 
                    lg:grid-cols-2 
                    xl:grid-cols-3 
                    gap-4
                    md:gap-16
                "
                v-else-if="customers && customersFiltered.length > 0">
                
                <CustomerCard 
                    v-for="(customer) in customersFiltered"
                    :key="customer.customerId"
                    :customer="customer"
                    @edit="customerEdit = customer"
                    class="slide-up-item "
                />
            </transition-group>
            
            <transition 
                v-else
                name="pop"
                appear>
                
                <div class="h-full w-full flex items-center justify-center pointer-events-none">
                    <div class="bg-white rounded-full p-12 text-center w-100 h-100">
                        <img 
                            src="/assets/illustrations/undraw_placeholders_rklh.svg" 
                            class="object-contain mb-8 w-48 h-48 mx-auto"
                            alt="No records illustrations"
                        />
                        <button 
                            class="button submit mx-auto pointer-events-auto"
                            @click="showCustomerNew = true">
                            
                            {{ $t('customers.createCustomerNow') }}
                        </button>
                    </div>
                </div>
            </transition>
        </div>
        
        <div 
            v-if="customerEdit"
            class="
                absolute inset-0 flex
                mb-16 
                md:mb-0
            ">
            
            <div 
                @click="customerEdit = null"
                class="flex-grow bg-black opacity-25 ">
                
            </div>
            
            <transition name="fade">
                <CustomerEdit 
                    :customer="customerEdit" 
                    @close="customerEdit = null"
                    class="
                        absolute bg-white right-0 overflow-hidden w-full
                        md:rounded-l-lg 
                        md:w-150
                    "
                />
            </transition>
        </div>
        
        <Modal 
            v-if="showCustomerNew"
            @close="showCustomerNew = false"
            insideClasses="bg-white">
            
            <header slot="header">
                <div class="py-4 px-6">
                    {{ $t('customers.newCustomer') }}
                </div>
            </header>
            
            <form 
                id="customers-form"
                @submit.prevent="createCustomer()"
                class="pb-2 px-6">
                
                <div class="flex">
                    <label class="w-auto mr-4">
                        <input 
                            type="radio"
                            v-model="customerNew.isCompany"
                            :value="true"
                            class="mr-2"
                        />
                        <span>{{ $t('customers.company') }}</span>
                    </label>
                    <label class="w-auto mr-4">
                        <input 
                            type="radio"
                            v-model="customerNew.isCompany"
                            :value="false"
                            class="mr-2"
                        />
                        <span>{{ $t('customers.personal') }}</span>
                    </label>
                </div>
               
                
                <InputSingleline 
                    v-if="customerNew.isCompany"
                    v-model="customerNew.name"
                    :label="$t('customers.companyName')"
                    name="companyName"
                    :placeholder="$t('customers.companyName')"
                />
                
                <InputSingleline 
                    v-model="customerNew.contacts[0].name"
                    :label="$t('customers.name')"
                    name="name"
                    :placeholder="$t('customers.name')"
                />
                
                <InputSingleline 
                    v-model="customerNew.contacts[0].email"
                    :label="$t('customers.email')"
                    name="email"
                    :placeholder="$t('customers.email')"
                />
            </form>
            
            <footer slot="footer">
                <div class="buttons-wrapper">
                    <a 
                        class="button transparent link" 
                        @click="showCustomerNew = false">
                        
                        {{ $t('general.cancel') }}
                    </a>
                    
                    <button 
                        type="submit"
                        @click.prevent="createCustomer()" 
                        :disabled="customerCreating"
                        class="button submit"
                        :class="{'loading' : customerCreating}">
                        
                        {{ $t('customers.addCustomer') }}
                    </button>
                </div>
            </footer>
            
        </Modal>
    </div>
</template>

<script>
    export default {
        components: {
            'CustomerCard': () => import('@/components/customers/CustomerCard.vue'),
            'CustomerEdit': () => import('@/components/customers/CustomerEdit.vue'),
        },
        
        computed:{
            customers(){
                return this.$store.getters.getCustomers;
            },
            
            customersFiltered(){
                if (!this.searchString) {
                    return this.customers.filter( c => !c.deletedAt );
                }
                
                return this.customers.filter( c => {
                    let itemValues = [
                        c.customerId,
                    ];
                    
                    // flatten all values into a CSV string
                    for ( const k in c ){
                        if ( 
                            !k.endsWith('Id') && 
                            typeof c[k] != 'object' ) {
                            itemValues.push( c[k] );
                        }
                        else if ( k == 'contacts') {
                             c[k].forEach( contact => {
                                 for ( const cK in contact ){
                                     if (!cK.endsWith('Id')) {
                                         itemValues.push( contact[cK] );
                                     }
                                 }
                             })
                        }
                    }
                    
                    const matchString = itemValues.join(';').toLowerCase();
                    
                    if ( matchString.includes( this.searchString.toLowerCase().trim() )) {
                        return c;
                    }
                });
            }
        },
        
        data(){
            return {
                showCustomerNew: false,
                refreshing: false,
                loading: true,
                customerEdit: null,
                customerNew: {
                    isCompany: false,
                    contacts: [
                        {
                            name: null,
                            email: null,
                        }
                    ]
                },
                customerCreating: false,
                searchString: '',
            }
        },
        
        methods: {
            async createCustomer(){
                try {
                    if (
                        this.customerNew.isCompany &&
                        !this.customerNew.name) {
                            
                        throw Error( this.$t('customers.needName') )
                    }
                    else if (
                        !this.customerNew.isCompany &&
                        !this.customerNew.contacts[0].name) {
                            
                        throw Error( this.$t('customers.nameFieldMissing') )
                    }
                    
                    this.customerCreating = true;
                    this.customerNew.contacts[0].contactId = ('contact_'+Math.random()*10000).replace('.','');
                    const customer = await this.$store.dispatch('createCustomer', this.customerNew);
                    
                    this.customerNew = {
                        isCompany: false,
                        contacts: [
                            {
                                name: null,
                                email: null,
                            }
                        ]
                    };
                    this.showCustomerNew = false;
                    this.customerEdit = customer;
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        // title: 'Error', 
                        text: e.message || this.$t('customers.problemCreating')
                    });
                    
                    console.error(e);
                } 
                finally {
                    this.customerCreating = false;
                }
            },
            
            async refresh(){
                this.refreshing = true;
                await this.$store.dispatch('listCustomers');
                this.refreshing = false;
            },
        },
        
        async created(){
            this.loading = false;
        }
    }
</script>

<style lang="scss" >

</style>
